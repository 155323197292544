import React from 'react';

import {
    Expander,
    ExpanderItem,
} from '@brandedholdings/react-components';




export default class SectionFAQs extends React.Component {
    static propTypes = {

    };

    static defaultProps = {

    };

    
    render() {
        
        return (
            <Expander altStyle={false}>
                <ExpanderItem title="I have bad credit. Can I still find a lender?">
                    <p><strong>Yes.</strong> You may still qualify for a loan with bad credit.</p>
                </ExpanderItem>
                <ExpanderItem title="What's my interest rate and when do I need to pay back the loan?">
                    <p>Your interest rate and repayment schedule will vary based on the lender you're connected with. Once you are connected with a lender, you'll be transferred to the lender's website to review and approve the repayment terms for your loan.</p>
                </ExpanderItem>
                <ExpanderItem title="How will I know if I am connected with a lender?">
                    <p>After you submit your information, you will receive an on-screen message. Please note: It may take up to a minute or more to determine if a lender will work with you, so do not close or navigate away from this page after completing the form.</p>
                </ExpanderItem>
                <ExpanderItem title="What can I use my loan for?">
                    <p>You can use your funds for any purpose. Personal expenses such as auto repairs, debt consolidation, home improvement and medical expenses are common reasons.</p>
                </ExpanderItem>
                <ExpanderItem title="Can I get find a lender today?">
                    <p>If you get started today, you will receive a quick response. If you are connected with a lender, your funds could be in your account in as soon as tomorrow. Money transfer times may vary between lending partners and may depend on your individual financial institution.</p>
                </ExpanderItem>
                <ExpanderItem title="I have a fixed income. Can I still find a lender?">
                    <p><strong>Yes.</strong> Many lending partners specialize in providing loans to people who receive various types of benefits such as Social Security, Retirement, or Veteran's Benefits.</p>
                </ExpanderItem>
                <ExpanderItem title="Does it cost anything to get started?">
                    <p><strong>No.</strong> There is no cost to complete the form.</p>
                </ExpanderItem>
                <ExpanderItem title="I have a job and also receive benefits. Which form of income should I use on my form?">
                    <p>To make your form information as accurate as possible, specify the form of income that represents a higher percentage of your monthly income.</p>
                </ExpanderItem>
                <ExpanderItem title="How is my private information protected?">
                    <p>Your information is encrypted and securely transmitted to the lender, who then decides whether to fund your loan. We use AES256 bit encryption to protect all data in transit.</p>
                </ExpanderItem>
            </Expander>
        );
    }
};
