import PropTypes from 'prop-types';
import React from 'react';


import IconCash from '../../../images/icons/icon-cash.svg';
import IconDelivery from '../../../images/icons/icon-delivery.svg';
import IconHome from '../../../images/icons/icon-home.svg';
import IconLike from '../../../images/icons/icon-like.svg';
import IconMobile2 from '../../../images/icons/icon-mobile2.svg';
import IconSites from '../../../images/icons/icon-sites.svg';
import IconSmileFace from '../../../images/icons/icon-smile-face.svg';
import IconHexagon from '../../../images/icons/icon-hexagon.svg';
import IconHearth from '../../../images/icons/icon-hearth.svg';

class SectionRequirements extends React.Component {

    render() {

        const {hexagon} = this.props || false;
        
        if (hexagon) {
            return (
                <>
                    <div className="icon-group icon-group--background">
                        <IconHexagon />
                        <div>
                            <IconSites className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    Valid ID
                                </h5>
                                US citizen or permanent resident, 18+ years old with a valid state id
                            </div>
                        </div>
                    </div>
                    <div className="icon-group icon-group--background">
                        <IconHexagon />
                        <div>
                            <IconSmileFace className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    SSN
                                </h5>
                                Your social security number is needed to verify your&nbsp;identity
                            </div>
                        </div>
                    </div>
                    <div className="icon-group icon-group--background">
                        <IconHexagon />
                        <div>
                            <IconMobile2 className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    Cell Phone
                                </h5>
                                A home or cell phone is required to contact&nbsp;you
                            </div>
                        </div>
                    </div>
                    <div className="icon-group icon-group--background">
                        <IconHexagon />
                        <div>
                            <IconCash className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    Bank Account
                                </h5>
                                You&rsquo;ll need a checking or savings&nbsp;account
                            </div>
                        </div>
                    </div>
                    <div className="icon-group icon-group--background">
                        <IconHexagon />
                        <div>
                            <IconHome className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    Home Address
                                </h5>
                                It does not matter if you rent&nbsp;or&nbsp;own
                            </div>
                        </div>
                    </div>
                    <div className="icon-group icon-group--background">
                        <IconHexagon />
                        <div>
                            <IconDelivery className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    Employment
                                </h5>
                                Must be employed, self-employed or receiving&nbsp;benefits
                            </div>
                        </div>
                    </div>
                    <div className="icon-group icon-group--background">
                        <IconHexagon />
                        <div>
                            <IconLike className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    Any Credit
                                </h5>
                                It doesn&rsquo;t matter what your credit&nbsp;score&nbsp;is!
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        return (
            <>
                <div className="layout--4-column-split">
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconSites className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    Driver&rsquo;s License
                                </h5>
                                <p>
                                    A valid state driver&rsquo;s license is required for each&nbsp;request
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconSmileFace className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    SSN
                                </h5>
                                <p>
                                    Your social security number is needed to verify your&nbsp;identity
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconMobile2 className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    Cell Phone
                                </h5>
                                <p>
                                    A home or cell phone is required to contact&nbsp;you
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconHearth className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    At Least 18
                                </h5>
                                <p>
                                    US citizens or permanent residents over 18&nbsp;years&nbsp;old
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="layout--4-column-split">
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconCash className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    Bank Account
                                </h5>
                                <p>
                                    You&rsquo;ll need a checking or savings&nbsp;account
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconHome className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    Home Address
                                </h5>
                                <p>
                                    It does not matter if you rent&nbsp;or&nbsp;own
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconDelivery className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    Employment
                                </h5>
                                <p>
                                    You must be employed, self-employed or receiving&nbsp;benefits
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="layout-content center">
                        <div className="icon-group">
                            <IconLike className="icon-group--icon" />
                            <div className="icon-group--text">
                                <h5>
                                    Any Credit
                                </h5>
                                <p>
                                    It doesn&rsquo;t matter what your credit&nbsp;score&nbsp;is!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

SectionRequirements.propTypes = {
    hexagon: PropTypes.bool,
};


export default SectionRequirements;