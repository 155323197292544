import PropTypes from 'prop-types';
import React from 'react';

import {
    ScrollMarker,
    TrustIcons
} from '@brandedholdings/react-components';

import { siteMeta } from '../../../data/site_meta.yml';



class SectionCTA extends React.Component {

    focusPersonalLoanForm(e) {
        const formScrollMarker = 'heroStart';
        
        if (ScrollMarker.exists(formScrollMarker)) {
            e.preventDefault();
            ScrollMarker.scrollToMarker(formScrollMarker);
        }
    }
    
    render() {

        
        const {howMuch} = this.props;

        let heading = <span>We do all of the hard&nbsp;work</span>;
        let subHeading = <p>You just have to fill out a few simple fields and we do the rest. You could have a response sooner than&nbsp;you&nbsp;think!</p>;
        let cta = 'Get Started';

        if (howMuch) {
            heading = <span>How much could a lender offer you?</span>
            subHeading = <p>Just answer a few questions about yourself and see if a lender is willing to work with you in minutes. Filling out the form won't affect your credit score.</p>
        }
        


        return (
            <div className="layout cta-section">
                <div className="layout-content">
                    <div className="layout-content__header center">
                        <h2 className="heading__main heading__focus">
                            {heading}
                        </h2>
                    </div>
                    <div className="layout-content__body center">
                        {subHeading}
                        <p>
                            <button
                                onClick={this.focusPersonalLoanForm.bind(this)}
                                className="button button--big button--success button--full start-form button--shadow">
                                    <span className="button--icon icon--padlock" />{cta}
                            </button>
                        </p>
                        <TrustIcons icons={['mcafee','secured']} height={26} siteMeta={siteMeta} />
                    </div>
                </div>
            </div>
        );
    }
};

SectionCTA.propTypes = {
    howMuch: PropTypes.bool,
};


export default SectionCTA;
